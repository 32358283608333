<div [class.dark-theme]="formDarkTheme.value">
  <mat-drawer-container class="fullpage">
    <mat-drawer #drawer class="sidenav" mode="over">
      <mat-list>
        <mat-list-item>
          <strong>NijiMado</strong>
        </mat-list-item>
      </mat-list>
      <mat-nav-list>
        <a
          mat-list-item
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
          >Play</a
        >
        <a
          mat-list-item
          [routerLink]="['/about']"
          routerLinkActive="router-link-active"
          >About</a
        >
      </mat-nav-list>
      <mat-list>
        <mat-list-item>
          <mat-slide-toggle [formControl]="formDarkTheme"
            >Dark Mode</mat-slide-toggle
          >
        </mat-list-item>
        <mat-list-item>
          <mat-slide-toggle [formControl]="formEnglish"
            >English</mat-slide-toggle
          >
        </mat-list-item>
      </mat-list>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <div mat-subheader>Stats</div>
        <a
          mat-list-item
          [routerLink]="['/stats/hourly']"
          routerLinkActive="router-link-active"
          >毎時 - Hourly</a
        >
      </mat-nav-list>
      <mat-divider></mat-divider>
      <ng-container *ngIf="dev$ | async">
        <mat-action-list>
          <div mat-subheader>開発版 - Dev</div>
          <ng-container
            *ngIf="providerIcon$ | async as icon; else loginTemplate"
          >
            <a
              mat-list-item
              style="text-decoration: none"
              [routerLink]="['/add-channel']"
              routerLinkActive="router-link-active"
            >
              {{ 'チャンネル追加' | translate }}
            </a>
            <button mat-list-item (click)="logout()">
              <fa-icon [icon]="icon"></fa-icon>
              <span style="width: 1rem"></span>
              {{ 'ログアウト' | translate }}
            </button>
          </ng-container>
          <ng-template #loginTemplate>
            <button mat-list-item (click)="login()">
              {{ 'ログイン' | translate }}
            </button>
          </ng-template>
        </mat-action-list>
      </ng-container>
    </mat-drawer>

    <div class="toggle-button-wrapper">
      <button
        mat-flat-button
        aria-label="toggle side navigation"
        class="toggle-button"
        (click)="drawer.toggle()"
      >
        <ng-container *ngIf="drawer.opened; else elseBlock">
          <mat-icon>arrow_left</mat-icon>
        </ng-container>
        <ng-template #elseBlock>
          <mat-icon>arrow_right</mat-icon>
        </ng-template>
      </button>
    </div>
    <router-outlet></router-outlet>
  </mat-drawer-container>
</div>

import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../environments/environment';
import { globalReducer, State as GlobalState } from '../store/global';

export interface State {
  global: GlobalState;
}
export const reducers: ActionReducerMap<State> = {
  global: globalReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { faGoogle, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { LoginComponent } from './components/login/login.component';
import {
  selectDev,
  selectLang,
  selectTheme,
  setLang,
  setTheme,
  State,
} from './store/global';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  formDarkTheme = new FormControl(
    window.matchMedia('(prefers-color-scheme: dark)').matches,
  );

  formEnglish = new FormControl(false);

  shouldEmphasizeSideNav$ = this.router.events.pipe(
    filter((ev) => ev instanceof NavigationEnd),
    map((ev: NavigationEnd) => !ev.urlAfterRedirects.endsWith('home')),
  );

  providerIcon$ = this.afAuth.user.pipe(
    map((user) => {
      const providerId = user?.providerData[0]?.providerId;
      if (providerId?.includes('google')) {
        return faGoogle;
      } else if (providerId?.includes('twitter')) {
        return faTwitter;
      } else {
        return null;
      }
    }),
  );
  faTwitter = faTwitter;
  faGoogle = faGoogle;

  dev$ = this.store.select(selectDev);

  private readonly subscription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<State>,
    private afAuth: AngularFireAuth,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    translate.setDefaultLang('ja');
    translate.use('ja');
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(selectTheme)
        .pipe(
          tap((theme) =>
            this.formDarkTheme.patchValue(theme === 'dark', {
              emitEvent: false,
            }),
          ),
        )
        .subscribe(),
    );
    this.subscription.add(
      this.formDarkTheme.valueChanges
        .pipe(
          distinctUntilChanged(),
          tap((isDark) =>
            this.store.dispatch(setTheme({ theme: isDark ? 'dark' : 'light' })),
          ),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.store
        .select(selectLang)
        .pipe(
          tap((lang) => this.translate.use(lang)),
          tap((lang) =>
            this.formEnglish.setValue(lang === 'en', { emitEvent: false }),
          ),
        )
        .subscribe(),
    );
    this.subscription.add(
      this.formEnglish.valueChanges
        .pipe(
          distinctUntilChanged(),
          tap((useEnglish) =>
            this.store.dispatch(setLang({ lang: useEnglish ? 'en' : 'ja' })),
          ),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async login() {
    if (await this.afAuth.currentUser) {
      return;
    }
    this.dialog.open(LoginComponent);
  }
  logout() {
    this.afAuth.signOut();
  }
}

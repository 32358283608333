import { Injectable } from '@angular/core';

import { LiveFilter } from '../models/common';

export interface HomeSetting {
  playerGridCol: number;
  filter: LiveFilter;
  externalLives: string[];
  isShowingButtons: boolean;
}

const initialHomeSetting: HomeSetting = {
  playerGridCol: 2,
  filter: {
    upcoming: false,
    jp: true,
    id: true,
    kr: true,
    en: true,
    holo: false,
    vr: false,
    other: false,
    bilibili: false,
    private: false,
  },
  externalLives: [],
  isShowingButtons: true,
};

export interface GlobalSetting {
  theme?: 'dark' | 'light';
  dev?: boolean;
  lang?: 'en' | 'ja';
}

const initialGlobalSetting: GlobalSetting = { lang: 'ja' };

const globalSettingKey = 'setting:global';
const homeSettingKey = 'setting:home';
const lastReadNotificationIdKey = 'lastReadNotificationId';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  homeSetting: HomeSetting;
  globalSetting: GlobalSetting;
  lastReadNotificationId: number;

  constructor() {
    this.loadHomeSetting();
    this.loadLastReadNotificationId();
  }

  loadHomeSetting() {
    const str = localStorage.getItem(homeSettingKey);
    if (!str) {
      this.homeSetting = initialHomeSetting;
      this.updateHomeSetting(this.homeSetting);
    } else {
      this.homeSetting = JSON.parse(str);
      this.homeSetting.externalLives =
        this.homeSetting.externalLives ?? initialHomeSetting.externalLives;
      this.homeSetting.playerGridCol =
        this.homeSetting.playerGridCol ?? initialHomeSetting.playerGridCol;
      this.homeSetting.filter =
        this.homeSetting.filter ?? initialHomeSetting.filter;
      this.homeSetting.isShowingButtons =
        this.homeSetting.isShowingButtons ??
        initialHomeSetting.isShowingButtons;
    }
    return this.homeSetting;
  }

  updateHomeSetting(setting: HomeSetting) {
    this.homeSetting = setting;
    const str = JSON.stringify(this.homeSetting);
    localStorage.setItem(homeSettingKey, str);
  }

  loadLastReadNotificationId() {
    this.lastReadNotificationId = Number(
      localStorage.getItem(lastReadNotificationIdKey),
    );
    return this.lastReadNotificationId;
  }

  updateLastReadNotificationId() {
    this.lastReadNotificationId = Date.now();
    localStorage.setItem(
      lastReadNotificationIdKey,
      String(this.lastReadNotificationId),
    );
  }

  loadGlobalSetting() {
    const str = localStorage.getItem(globalSettingKey);
    if (!str) {
      this.globalSetting = initialGlobalSetting;
      this.updateGlobalSetting(this.globalSetting);
    } else {
      this.globalSetting = JSON.parse(str);
    }
    return this.globalSetting;
  }

  updateGlobalSetting(setting: Partial<GlobalSetting>) {
    this.globalSetting = { ...this.globalSetting, ...setting };
    const str = JSON.stringify(this.globalSetting);
    localStorage.setItem(globalSettingKey, str);
  }

  canMultiPlay() {
    const pauseOther = localStorage.getItem('rulePauseOther');
    if (pauseOther === null) {
      localStorage.setItem('rulePauseOther', '1');
    }
    return pauseOther === '0';
  }

  canAutoPlay() {
    const autoPlay = localStorage.getItem('ruleAutoPlay');
    if (autoPlay === null) {
      localStorage.setItem('ruleAutoPlay', '0');
    }
    return autoPlay === '1';
  }
}

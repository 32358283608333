<div>
  <mat-action-list>
    <button mat-list-item (click)="login('twitter')">
      <fa-icon
        [icon]="faTwitter"
        [title]="'Twitterアカウントでログイン' | translate"
      ></fa-icon>
      <span class="space"></span>
      <span translate>Twitterアカウントでログイン</span>
    </button>
    <button mat-list-item (click)="login('google')">
      <fa-icon
        [icon]="faGoogle"
        [title]="'Googleアカウントでログイン' | translate"
      ></fa-icon>
      <span class="space"></span>
      <span translate>Googleアカウントでログイン</span>
    </button>
  </mat-action-list>
</div>

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

const TITLE = 'NijiMado | About';
const CANONICAL = 'https://niji-mado.web.app/about';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
  constructor(title: Title, @Inject(DOCUMENT) doc: Document) {
    const link = doc.querySelector('link[rel=canonical]');
    link?.setAttribute('href', CANONICAL);
    title.setTitle(TITLE);
  }
}

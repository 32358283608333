import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialogRef } from '@angular/material/dialog';
import { faGoogle, faTwitter } from '@fortawesome/free-brands-svg-icons';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  faTwitter = faTwitter;
  faGoogle = faGoogle;

  constructor(
    private afAuth: AngularFireAuth,
    private refDialog: MatDialogRef<LoginComponent>,
  ) {}

  async login(type: 'twitter' | 'google') {
    if (type === 'twitter') {
      await this.afAuth.signInWithPopup(
        new firebase.auth.TwitterAuthProvider(),
      );
    } else if (type === 'google') {
      await this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = type; // type が 'twitter' でも 'google' でもない場合にコンパイルエラーを出す
    }
    this.refDialog.close();
  }
}

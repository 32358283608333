export const firebaseConfig = {
  apiKey: 'AIzaSyBssFZvpP6UexRApGaZDXIgOgcf1MYmdE8',
  authDomain: 'niji-mado.firebaseapp.com',
  databaseURL: 'https://niji-mado.firebaseio.com',
  projectId: 'niji-mado',
  storageBucket: 'niji-mado.appspot.com',
  messagingSenderId: '320479325156',
  appId: '1:320479325156:web:34248f31298610017a75f1',
  measurementId: 'G-P0V9BVE9S0',
};

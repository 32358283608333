import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import {
  createAction,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
  props,
  select,
  Store,
} from '@ngrx/store';
import { first, map, tap } from 'rxjs/operators';

import { LocalStorageService } from '../services/local-storage.service';

export interface State {
  theme: 'light' | 'dark';
  dev: boolean;
  lang: 'en' | 'ja';
}
export const initialGlobalState: State = {
  theme: 'dark',
  dev: false,
  lang: 'ja',
};

export const toggleDev = createAction('[Global] Toggle Dev');
export const setTheme = createAction(
  '[Global] Set theme',
  props<{ theme: 'light' | 'dark' }>(),
);
export const setLang = createAction(
  '[Global] Set lang',
  props<{ lang: 'en' | 'ja' }>(),
);
export const setState = createAction(
  '[Global] Set State',
  props<{ value: State }>(),
);

export const globalReducer = createReducer(
  initialGlobalState,
  on(toggleDev, (state) => ({
    ...state,
    dev: !state.dev,
  })),

  on(setTheme, (state, { theme }) => ({
    ...state,
    theme,
  })),

  on(setLang, (state, { lang }) => ({
    ...state,
    lang,
  })),

  on(setState, (state, { value }) => ({
    ...state,
    ...value,
  })),
);

export const globalFeatureKey = 'global';
export const selectGlobalState = createFeatureSelector<State>(globalFeatureKey);
export const selectTheme = createSelector(selectGlobalState, (s) => s.theme);
export const selectDev = createSelector(selectGlobalState, (s) => s.dev);
export const selectLang = createSelector(selectGlobalState, (s) => s.lang);

@Injectable()
export class GlobalEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => {
        const setting = this.localStorageService.loadGlobalSetting();
        return setState({
          value: {
            theme: setting.theme ?? 'dark',
            dev: !!setting.dev,
            lang: setting.lang ?? 'ja',
          },
        });
      }),
    ),
  );

  storeGlobalSetting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleDev, setTheme, setLang),
        tap(async () => {
          const state = await this.store
            .pipe(first(), select(selectGlobalState))
            .toPromise();
          this.localStorageService.updateGlobalSetting({ ...state });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private localStorageService: LocalStorageService,
  ) {}
}

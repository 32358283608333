<h1 translate>NijiMadoについて</h1>

<p translate>このサイトはにじさんじ所属ライバーの非公式ファンサイトです。</p>

<p translate>HOLOTools様を参考に、にじさんじ版を個人で開発しています。</p>
<p>
  <a href="https://hololive.jetri.co" target="_blank" rel="noopener"
    >HOLOTools</a
  >
</p>

<p style="display: none">
  <a href="https://niji-mado.fanbox.cc/" target="_blank" rel="noopener"
    >Pixiv FANBOX</a
  >
</p>

<h2 translate>お問い合わせ</h2>

<p translate>ご意見、苦情等はこちらからご連絡ください。</p>
<ul>
  <li>
    <a href="https://twitter.com/niji_mado" target="_blank" rel="noopener"
      >twitter</a
    >
  </li>
  <li>
    <a
      href="https://marshmallow-qa.com/niji_mado"
      target="_blank"
      rel="noopener"
      translate
    >
      マシュマロ
    </a>
  </li>
</ul>

<h2 translate>免責事項</h2>
<ol>
  <li translate>
    NijiMadoに掲載されている情報の正確性および完全性などに関して、NijiMadoはいかなる保証もするものではありません。
  </li>
  <li translate>
    NijiMadoは予告なしにサービスを停止または中止する場合があります。
  </li>
  <li translate>
    NijiMadoの利用者様がNijiMadoを利用されたこと、またはNijiMadoが何らかの原因で利用できなかったことによりNijiMadoの利用者様に生じる一切の損害やトラブルについて、NijiMadoは何ら責任を負うものではありません。
  </li>
  <li>
    {{
      'NijiMadoはアクセス解析サービス（Google Analytics）を利用するためにCookieを使用します。CookieにはNijiMadoの利用者様の個人を識別出来る情報は含まれておりません。NijiMadoの利用者様がご自身でブラウザの設定を変更することでCookieの使用を拒否することも出来ます。ただし、この場合はNijiMadoの機能の一部または全てがご利用できなくなることがあります。Google Analyticsのプライバシーポリシーについては以下をご参照ください。'
        | translate
    }}
    <p>
      <a
        href="https://policies.google.com/"
        target="_blank"
        rel="noopener"
        translate
        >Google プライバシーポリシー
      </a>
    </p>
  </li>
  <li>
    {{
      'NijiMadoでは、利用者さまに適切な動画を表示するため、YouTube APIサービスを利用しています。YouTube APIサービスは、YouTubeの利用規約に基づいて提供されています。YouTubeの利用規約については以下をご覧ください。利用者様はNijiMadoを利用することで上記YouTubeの利用規約に同意したものとみなされます。'
        | translate
    }}
    {{
      'NijiMadoは YouTube APIサービスを利用して YouTube の配信を埋め込んでいます。NijiMadoは本APIを通じて個人情報を一切取得・利用しておりません。YouTube によって埋め込まれたプレイヤーに広告が表示される可能性があります。'
        | translate
    }}
    <p>
      <a
        href="https://www.youtube.com/t/terms"
        target="_blank"
        rel="noopener"
        translate
        >YouTube 利用規約
      </a>
    </p>
  </li>
</ol>

<h2 translate>アフィリエイト参加者宣言</h2>
<p>
  {{
    'NijiMado（niji-mado.web.app）は、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。'
      | translate
  }}
</p>

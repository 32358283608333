import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './pages/about/about.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/about' },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./pages/stats/stats.module').then((m) => m.StatsModule),
  },
  {
    path: 'add-channel',
    loadChildren: () =>
      import('./pages/add-channel/add-channel.module').then(
        (m) => m.AddChannelModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
